import React, { Fragment } from "react";
const Icon = () => {
  return (
    <Fragment>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 40 40"
        xml="preserve"
      >
        <path
          id="background_1_"
          className="st0"
          d="M40,5c0-2.76-2.24-5-5-5H5C2.24,0,0,2.24,0,5v30c0,2.76,2.24,5,5,5h30c2.76,0,5-2.24,5-5V5z
	"
        />
        <path
          className="st1"
          d="M20,23.1l14.67-12.71H5.33L20,23.1z M16.04,21.7L20,24.95l3.9-3.25l10.77,9.23H5.33L16.04,21.7z M4.35,29.95
	V11.37l10.76,9.29L4.35,29.95z M35.65,29.95V11.37l-10.76,9.29L35.65,29.95z"
        />
      </svg>

      <style jsx>{`
        .st0 {
          fill-rule: evenodd;
          clip-rule: evenodd;
          fill: #b39c57;
        }
        .st1 {
          fill-rule: evenodd;
          clip-rule: evenodd;
          fill: #ffffff;
        }
      `}</style>
    </Fragment>
  );
};

export default Icon;
