import React, { Fragment } from "react";
const TwitterIcon = () => {
  return (
    <Fragment>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 40 40"
        style={{ enableBackground: "new 0 0 40 40" }}
        xmlSpace="preserve"
      >
        <path
          id="background"
          className="st0"
          d="M40,5c0-2.8-2.2-5-5-5H5C2.2,0,0,2.2,0,5v30c0,2.8,2.2,5,5,5h30c2.8,0,5-2.2,5-5V5z"
        />
        <path
          className="st1"
          d="M35,10.7c-1.1,0.5-2.3,0.8-3.5,1c1.3-0.8,2.2-2,2.7-3.4C33,9,31.7,9.5,30.3,9.8c-1.1-1.2-2.7-1.9-4.5-1.9
	c-3.4,0-6.2,2.8-6.2,6.2c0,0.5,0.1,1,0.2,1.4c-5.1-0.3-9.7-2.7-12.7-6.4c-0.5,0.9-0.8,2-0.8,3.1c0,2.1,1.1,4,2.7,5.1
	c-1,0-2-0.3-2.8-0.8c0,0,0,0.1,0,0.1c0,3,2.1,5.5,4.9,6c-0.5,0.1-1.1,0.2-1.6,0.2c-0.4,0-0.8,0-1.2-0.1c0.8,2.4,3.1,4.2,5.7,4.3
	c-2.1,1.7-4.8,2.6-7.6,2.6c-0.5,0-1,0-1.5-0.1c2.7,1.7,6,2.8,9.4,2.8c11.3,0,17.5-9.4,17.5-17.5c0-0.3,0-0.5,0-0.8
	C33.1,13,34.2,11.9,35,10.7z"
        />
      </svg>
      <style jsx>{`
        .st0 {
          fill-rule: evenodd;
          clip-rule: evenodd;
          fill: #b39c57;
        }
        .st1 {
          fill: #ffffff;
        }
      `}</style>
    </Fragment>
  );
};

export default TwitterIcon;
