import React, { useState, Fragment } from "react"
import PropTypes from "prop-types"
import ResponsiveMenu from "react-responsive-navbar"
import FinkaLogoBlack from "../components/svg/finka-logo-black"
import BackIconWhite from "../components/svg/back-icon-white"
import HamburgerIconWhite from "../components/svg/hamburger-icon-white"

const FooterNav = ({ locale }) => {
  const [isScrolled, setIsScrolled] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  const handleActiveNav = id => {
    var elems = document.querySelectorAll(".menu-links-footer")
    ;[].forEach.call(elems, function(el) {
      el.className = el.className.replace(/\bactive-footer-nav\b/, "")
    })
    document.getElementById(id).classList.add("active-footer-nav")
  }

  return (
    <Fragment>
      <div
        style={{
          height:
            isScrolled && !isMobile
              ? document.getElementById("navigation").offsetHeight
              : 0,
        }}
      ></div>
      <div
        id="navigation-footer"
        className={isScrolled && !isMobile && "scrolled-nav"}
      >
        <div className="row middle-sm middle-sm around-sm">
          <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
            <div className="box middle-sm middle-xs">
              <nav>
                <ResponsiveMenu
                  menuOpenButton={<HamburgerIconWhite />}
                  menuCloseButton={<BackIconWhite className="menu-button" />}
                  changeMenuOn="769px"
                  largeMenuClassName="large-menu-classname"
                  smallMenuClassName="small-menu-classname"
                  menu={
                    <ul>
                      <a href={`https://finka.ch/${locale}`}>
                        <li
                          className="normal-text menu-links-footer white"
                          id="home-nav"
                          onClick={() => handleActiveNav("home-nav")}
                        >
                          Home
                        </li>
                      </a>
                      <a href={`https://finka.ch/${locale}/about`}>
                        <li
                          className="normal-text menu-links-footer white"
                          id="about-nav"
                          onClick={() => handleActiveNav("about-nav")}
                        >
                          {locale == "de" ? "Über Finka" : "About"}
                        </li>
                      </a>
                      <a href={`https://blog.finka.ch/${locale}`}>
                        <li
                          className="normal-text menu-links-footer white"
                          id="blog-nav"
                          onClick={() => handleActiveNav("blog-nav")}
                        >
                          Blog
                        </li>
                      </a>
                      <a href={`https://finka.ch/${locale}/faqs`}>
                        <li
                          className="normal-text menu-links-footer white"
                          id="faqs-nav"
                          onClick={() => handleActiveNav("faqs-nav")}
                        >
                          {locale == "de" ? "Fragen & Antworten" : "FAQs"}
                        </li>
                      </a>
                      <a href={`https://finka.ch/${locale}/la-pradera`}>
                        <li
                          className="normal-text menu-links-footer white"
                          id="faqs-nav"
                          onClick={() => handleActiveNav("faqs-la-pradera")}
                        >
                          La Pradera
                        </li>
                      </a>
                      <a href={`https://finka.ch/${locale}/#contact`}>
                        <li
                          className="normal-text menu-links-footer white"
                          id="contact-nav"
                          onClick={() => handleActiveNav("contact-nav")}
                        >
                          {locale == "de" ? "Kontakt" : "Contact"}
                        </li>
                      </a>
                    </ul>
                  }
                />
              </nav>
            </div>
          </div>
        </div>
        <style jsx>{`
          .active-footer-nav {
            border-top: 3px solid #fff;
          }
          #navigation-footer {
            width: 90%;
            margin: auto;
            padding-top: 1rem;
          }
          .scrolled-nav {
            background-color: #efefef;
            position: fixed;
            width: 100%;
            padding-left: 5%;
            padding-right: 5%;
            z-index: 9999;
            top: 0px;
            transition: top 0.3s;
            border-bottom: 1px solid white;
            height: auto;
          }
          .small-menu-classname {
            text-align: right !important;
          }
          #navigation-footer .logo {
            margin: 0.5rem 1.4rem;
          }
          .menu-links-footer {
            margin: 0rem 1.4rem;
            padding: 0.5rem 0rem;
          }
          .menu-links-footer:hover {
            border-top: 3px solid #fff;
            border-bottom: none !important;
          }
          .button-link {
            margin: 0.5rem 1.4rem;
          }
          #navigation-footer li {
            display: inline;
          }
          #navigation-footer ul {
            display: inline-block;
          }

          @media only screen and (max-width: 768px) {
            #navigation-footer {
              width: 100%;
              margin: auto;
              transition-timing-function: ease;
              transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
            }
            #navigation-footer li {
              display: block;
              text-align: center;
              color: #414042 !important;
            }
            #navigation-footer ul {
              width: 100%;
              margin: auto;
            }
            .nav-button {
              display: block;
              margin: auto;
            }
            .menu-button {
              width: 100%;
            }
            .menu-links-footer {
              background-color: white;
              margin: 0;
              padding: 1rem 0rem;
              border-bottom: 1px solid #efefef;
            }
          }
        `}</style>
      </div>
    </Fragment>
  )
}

FooterNav.propTypes = {
  locale: PropTypes.string.isRequired,
}

export default FooterNav
