import React, { Fragment } from "react";

const FinkaLogoWhite = () => {
  return (
    <Fragment>
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 482.63 55"
        fill="#fff"
      >
        <defs></defs>
        <title>FinkaLogoWhite</title>
        <path
          className="cls-1"
          d="M283.4,241.64H264.52v9.06h16.74V256H264.52v13.52h-5.84V236.33H283.4Z"
          transform="translate(-258.68 -222.5)"
        />
        <path
          className="cls-1"
          d="M307.83,236.33v33.21H302V236.33Z"
          transform="translate(-258.68 -222.5)"
        />
        <path
          className="cls-1"
          d="M351.76,236.33h5.74v33.21h-4.88L334.3,245.91v23.63h-5.73V236.33H334l17.79,23Z"
          transform="translate(-258.68 -222.5)"
        />
        <path
          className="cls-1"
          d="M403,269.54l-13.66-16.79L381,261.09v8.45h-2.46V236.33H381v21.82l21.16-21.82h3.37L391,251l15.14,18.55Z"
          transform="translate(-258.68 -222.5)"
        />
        <polygon
          className="cls-1"
          points="180.66 13.59 178.28 13.59 162.82 47.04 165.38 47.04 169.6 37.74 169.63 37.74 173.17 29.98 173.17 29.98 179.42 16.3 184.6 27.69 189.18 37.74 189.19 37.74 193.42 47.04 196.12 47.04 180.66 13.59"
        />
        <rect
          className="cls-1"
          x="279.01"
          y="13.79"
          width="24.71"
          height="6.01"
        />
        <path
          className="cls-1"
          d="M553,236.27v33.21h-5.83V236.27Z"
          transform="translate(-258.68 -222.5)"
        />
        <path
          className="cls-1"
          d="M735.58,236.27h5.74v33.21h-4.89l-18.31-23.62v23.62h-5.74V236.27h5.41l17.79,23Z"
          transform="translate(-258.68 -222.5)"
        />
        <path
          className="cls-2"
          d="M645.14,267.35l-12.78-14.64L624.64,260v7.36h-2.3v-29h2.3v19l19.79-19h3.15L634,251.18l14.15,16.17Z"
          transform="translate(-258.68 -222.5)"
        />
        <path
          className="cls-1"
          d="M693.48,241.59H674.6v9.06h16.74V256H674.6v13.52h-5.84V236.27h24.72Z"
          transform="translate(-258.68 -222.5)"
        />
        <rect
          className="cls-1"
          x="410.08"
          y="41.04"
          width="24.71"
          height="6.01"
        />
        <ellipse
          className="cls-3"
          cx="331.99"
          cy="30.38"
          rx="13.58"
          ry="14.29"
        />
        <rect
          className="cls-1"
          x="492.85"
          y="219.49"
          width="3.22"
          height="26.43"
          transform="translate(468.32 -484.33) rotate(89.98)"
        />
        <rect
          className="cls-1"
          x="223.28"
          y="9.32"
          width="24.98"
          height="1.77"
        />
        <rect
          className="cls-1"
          x="507.94"
          y="226.39"
          width="3.22"
          height="8.05"
          transform="translate(78.44 -524.06) rotate(47.71)"
        />
        <polygon
          className="cls-1"
          points="251.72 6.39 251.74 1.26 254.95 0 254.94 6.4 251.72 6.39"
        />
        <polygon
          className="cls-1"
          points="239.33 53.32 244.59 46.49 246.91 48.76 242.1 55 239.33 53.32"
        />
        <rect
          className="cls-1"
          x="498.34"
          y="272.5"
          width="7.17"
          height="1.77"
          transform="translate(-279.71 281.53) rotate(-52.38)"
        />
        <polygon
          className="cls-1"
          points="250.29 13.44 263.97 13.36 262.91 16.57 249.85 16.66 250.29 13.44"
        />
        <polygon
          className="cls-1"
          points="250.57 15.93 250.56 14.16 262.97 14.09 262.38 15.85 250.57 15.93"
        />
        <polygon
          className="cls-1"
          points="238.83 29.62 241.84 28.4 246.91 48.76 243.88 49.9 238.83 29.62"
        />
        <rect
          className="cls-1"
          x="500.66"
          y="251.69"
          width="1.77"
          height="19.91"
          transform="translate(-307.04 -93.58) rotate(-13.98)"
        />
        <polygon
          className="cls-1"
          points="238.83 29.62 242.84 22.97 245.79 24.34 241.35 31.64 238.83 29.62"
        />
        <rect
          className="cls-1"
          x="497.33"
          y="248.76"
          width="7.1"
          height="1.77"
          transform="translate(-231.41 325.31) rotate(-58.69)"
        />
        <polygon
          className="cls-1"
          points="242.84 22.97 249.59 22 250.05 25.19 242.94 26.22 242.84 22.97"
        />
        <rect
          className="cls-1"
          x="502.07"
          y="245.73"
          width="5.74"
          height="1.77"
          transform="translate(-288.94 -147.21) rotate(-8.28)"
        />
        <polygon
          className="cls-1"
          points="245.81 18.14 248.93 17.27 251.18 32.67 248.26 33.16 245.81 18.14"
        />
        <rect
          className="cls-1"
          x="506.39"
          y="240.65"
          width="1.77"
          height="14.12"
          transform="translate(-292.01 -137.38) rotate(-9.29)"
        />
        <polygon
          className="cls-1"
          points="245.81 18.14 250.29 13.44 252.88 15.35 247.9 20.63 245.81 18.14"
        />
        <rect
          className="cls-1"
          x="505"
          y="238.5"
          width="5.8"
          height="1.77"
          transform="translate(-273.48 221.95) rotate(-46.66)"
        />
        <polygon
          className="cls-1"
          points="250.49 21.01 257.69 21 257.21 24.22 250.94 24.23 250.49 21.01"
        />
        <polygon
          className="cls-1"
          points="258.02 21.15 260.57 13.38 263.97 13.36 261.23 21.71 258.02 21.15"
        />
        <polygon
          className="cls-1"
          points="260.62 21.24 258.93 20.69 261.1 14.1 262.97 14.09 260.62 21.24"
        />
        <polygon
          className="cls-1"
          points="255.04 21.75 259.92 18.73 261.23 21.71 257.15 24.22 255.04 21.75"
        />
        <rect
          className="cls-1"
          x="514.86"
          y="243.23"
          width="4.29"
          height="1.77"
          transform="translate(-309.83 85.58) rotate(-31.7)"
        />
        <polygon
          className="cls-1"
          points="246.75 36.65 248.4 30.66 251.18 32.67 250 36.94 246.75 36.65"
        />
        <rect
          className="cls-1"
          x="505.43"
          y="255.7"
          width="4.76"
          height="1.77"
          transform="translate(-133.34 455.3) rotate(-74.57)"
        />
        <polygon
          className="cls-1"
          points="237.13 47.33 243.05 40.19 245.18 42.43 239.68 49.13 237.13 47.33"
        />
        <polygon
          className="cls-1"
          points="245.41 37.75 247.84 34.49 250 36.94 246.4 41.48 245.41 37.75"
        />
        <polygon
          className="cls-1"
          points="237.88 49.4 237.13 47.33 238.55 45.64 240.72 46.03 241.47 48.1 240.05 49.79 237.88 49.4"
        />
        <rect
          className="cls-1"
          x="477.74"
          y="226.4"
          width="3.22"
          height="8.05"
          transform="translate(-272.34 207.47) rotate(-47.71)"
        />
        <polygon
          className="cls-1"
          points="219.81 6.4 219.79 1.27 216.58 0.01 216.59 6.41 219.81 6.4"
        />
        <polygon
          className="cls-1"
          points="232.61 53.32 227.35 46.49 225.03 48.76 229.84 55 232.61 53.32"
        />
        <rect
          className="cls-1"
          x="483.8"
          y="272.5"
          width="7.17"
          height="1.77"
          transform="translate(147.69 -502.04) rotate(52.38)"
        />
        <polygon
          className="cls-1"
          points="221.66 13.44 207.97 13.36 209.03 16.57 222.09 16.66 221.66 13.44"
        />
        <polygon
          className="cls-1"
          points="221.37 15.93 221.38 14.16 208.98 14.09 209.56 15.85 221.37 15.93"
        />
        <polygon
          className="cls-1"
          points="233.12 29.61 230.1 28.39 225.03 48.76 228.06 49.9 233.12 29.61"
        />
        <rect
          className="cls-1"
          x="486.88"
          y="251.69"
          width="1.77"
          height="19.91"
          transform="translate(-181.02 -332.59) rotate(13.98)"
        />
        <polygon
          className="cls-1"
          points="233.12 29.61 229.1 22.97 226.16 24.33 230.59 31.63 233.12 29.61"
        />
        <rect
          className="cls-1"
          x="484.89"
          y="248.76"
          width="7.1"
          height="1.77"
          transform="translate(189.17 -519.89) rotate(58.69)"
        />
        <polygon
          className="cls-1"
          points="229.1 22.97 222.36 22 221.89 25.18 229.01 26.22 229.1 22.97"
        />
        <rect
          className="cls-1"
          x="481.5"
          y="245.72"
          width="5.74"
          height="1.77"
          transform="translate(-218.12 -289.69) rotate(8.28)"
        />
        <polygon
          className="cls-1"
          points="226.13 18.14 223.01 17.27 220.76 32.66 223.68 33.16 226.13 18.14"
        />
        <rect
          className="cls-1"
          x="481.14"
          y="240.65"
          width="1.77"
          height="14.12"
          transform="translate(-212.39 -297.05) rotate(9.29)"
        />
        <polygon
          className="cls-1"
          points="226.13 18.14 221.66 13.44 219.07 15.35 224.04 20.62 226.13 18.14"
        />
        <rect
          className="cls-1"
          x="478.51"
          y="238.49"
          width="5.8"
          height="1.77"
          transform="translate(66.39 -497.53) rotate(46.66)"
        />
        <polygon
          className="cls-1"
          points="221.46 21 214.26 21 214.74 24.22 221 24.23 221.46 21"
        />
        <polygon
          className="cls-1"
          points="213.93 21.15 211.37 13.37 207.97 13.36 210.72 21.71 213.93 21.15"
        />
        <polygon
          className="cls-1"
          points="211.33 21.24 213.01 20.69 210.84 14.1 208.97 14.09 211.33 21.24"
        />
        <polygon
          className="cls-1"
          points="216.91 21.75 212.02 18.73 210.72 21.71 214.79 24.22 216.91 21.75"
        />
        <rect
          className="cls-1"
          x="470.16"
          y="243.22"
          width="4.29"
          height="1.77"
          transform="translate(-59.96 -434.26) rotate(31.7)"
        />
        <polygon
          className="cls-1"
          points="225.2 36.64 223.54 30.66 220.76 32.66 221.94 36.94 225.2 36.64"
        />
        <rect
          className="cls-1"
          x="479.12"
          y="255.69"
          width="4.76"
          height="1.77"
          transform="translate(342.01 -498.34) rotate(74.57)"
        />
        <polygon
          className="cls-1"
          points="234.81 47.32 228.9 40.19 226.76 42.43 232.26 49.13 234.81 47.32"
        />
        <polygon
          className="cls-1"
          points="226.53 37.74 224.1 34.49 221.94 36.94 225.54 41.48 226.53 37.74"
        />
        <polygon
          className="cls-1"
          points="234.06 49.4 234.81 47.32 233.39 45.63 231.22 46.02 230.47 48.1 231.89 49.79 234.06 49.4"
        />
        <polygon
          className="cls-1"
          points="242.1 55 242.5 51.77 229.47 51.77 229.84 55 242.1 55"
        />
      </svg>
      <style jsx>{`
        .cls-1,
        .cls-2 {
          fill: #fff;
        }
        .cls-2,
        .cls-3 {
          stroke: #fff;
          stroke-miterlimit: 10;
        }
        .cls-2 {
          stroke-width: 4px;
        }
        .cls-3 {
          fill: none;
          stroke-width: 6px;
        }
      `}</style>
    </Fragment>
  );
};

export default FinkaLogoWhite;
