import React, { useState } from "react"
import PropTypes from "prop-types"
import ResponsiveMenu from "react-responsive-navbar"
import FinkaLogoBlack from "components/svg/finka-logo-black"
import BackIcon from "components/svg/back-icon"
import HamburgerIcon from "components/svg/hamburger-icon"
import { navigate } from "gatsby"

const Header = ({ locale }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(locale)
  const onLanguageSelect = event => {
    let lang = event.target.value
    setSelectedLanguage(lang)
    navigate(`/${lang}`)
  }

  return (
    <section id="navigation">
      <div className="row end-sm middle-sm around-sm">
        <div
          className="col-xs-12 col-sm-12 col-md-3 col-lg-3"
          style={{ margin: 0, padding: 0 }}
        >
          <div className="box middle-sm">
            <div className="logo">
              <FinkaLogoBlack />
            </div>
          </div>
        </div>
        <div
          className="col-xs-12 col-sm-12 col-md-9 col-lg-9"
          style={{ margin: 0, padding: 0 }}
        >
          <div className="box end-sm end-xs">
            <nav>
              <ResponsiveMenu
                menuOpenButton={<HamburgerIcon />}
                menuCloseButton={<BackIcon className="menu-button" />}
                changeMenuOn="769px"
                largeMenuClassName="large-menu-classname"
                smallMenuClassName="small-menu-classname"
                menu={
                  <ul>
                    <a
                      href={`https://finka.ch/${locale}`}
                      className="dark-green"
                      style={{ textDecoration: "none" }}
                    >
                      <li
                        className="normal-text menu-links dark-green "
                        id="home-nav"
                      >
                        Home
                      </li>
                    </a>
                    <a
                      href={`https://finka.ch/${locale}/about`}
                      className="dark-green"
                      style={{ textDecoration: "none" }}
                    >
                      <li
                        className="normal-text menu-links dark-green "
                        id="about-nav"
                      >
                        {locale == "de" ? "Über Finka" : "About"}
                      </li>
                    </a>
                    <a
                      href={`https://blog.finka.ch/${locale}`}
                      className="dark-green"
                      style={{ textDecoration: "none" }}
                    >
                      <li
                        className={
                          "normal-text menu-links dark-green active-nav"
                        }
                        id="blog-nav"
                      >
                        Blog
                      </li>
                    </a>
                    <a
                      href={`https://finka.ch/${locale}/faqs`}
                      className="dark-green"
                      style={{ textDecoration: "none" }}
                    >
                      <li
                        className="normal-text menu-links dark-green "
                        id="faqs-nav"
                      >
                        {locale == "de" ? "Fragen & Antworten" : "FAQs"}
                      </li>
                    </a>
                    <a
                      href={`https://finka.ch/${locale}/la-pradera`}
                      className="dark-green"
                      style={{ textDecoration: "none" }}
                    >
                      <li
                        className="normal-text menu-links dark-green "
                        id="la-pradera-nav"
                      >
                        La Pradera
                      </li>
                    </a>
                    <a
                      href={`https://finka.ch/${locale}/#contact`}
                      className="dark-green"
                      style={{ textDecoration: "none" }}
                    >
                      <li
                        className="normal-text menu-links dark-green "
                        id="contact-nav"
                      >
                        {locale == "de" ? "Kontakt" : "Contact"}
                      </li>
                    </a>

                    <select
                      value={selectedLanguage}
                      onChange={onLanguageSelect}
                      className="lang-select-test dark-green"
                    >
                      <option value="en">en</option>
                      <option value="de">de</option>
                    </select>
                    <a
                      href={`https://finka.ch/${locale}/?buy=token`}
                      target="_blank"
                    >
                      <button className="call-to-action-button dark-green-button button-link nav-button">
                        {locale == "de" ? "TOKEN KAUFEN" : "BUY TOKEN"}
                      </button>
                    </a>
                  </ul>
                }
              />
            </nav>
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .lang-select-test:focus {
            outline: none;
          }
          .lang-select-test {
            outline: none;
            padding: 15px 26px 15px 15px;
            height: 50px !important;
            font-size: 16px !important;
            position: relative;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            border: none;
            background: transparent
              url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='18' height='16' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill=''/></g></svg>")
              no-repeat;
            background-position: right 5px top 50%;
          }
        `}
      </style>
    </section>
  )
}

Header.propTypes = {
  locale: PropTypes.string.isRequired,
}

export default Header
