import React, { Fragment } from "react";
const InstagramIcon = () => {
  return (
    <Fragment>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 40 40"
        style={{ enableBackground: "new 0 0 40 40" }}
        xmlSpace="preserve"
      >
        <style type="text/css"></style>
        <path
          id="background"
          className="st0"
          d="M40,5c0-2.8-2.2-5-5-5H5C2.2,0,0,2.2,0,5v30c0,2.8,2.2,5,5,5h30c2.8,0,5-2.2,5-5V5z"
        />
        <g id="comp_x5F_173-instagram">
          <g>
            <path
              className="st1"
              d="M20,12.3c-4.3,0-7.7,3.4-7.7,7.7c0,4.3,3.4,7.7,7.7,7.7c4.3,0,7.7-3.4,7.7-7.7S24.3,12.3,20,12.3z M20,25
			c-2.8,0-5-2.2-5-5s2.2-5,5-5c2.8,0,5,2.2,5,5C25,22.8,22.8,25,20,25L20,25z M29.8,12c0,1-0.8,1.8-1.8,1.8c-1,0-1.8-0.8-1.8-1.8
			s0.8-1.8,1.8-1.8C29,10.2,29.8,11,29.8,12z M34.9,13.8c-0.1-2.4-0.7-4.5-2.4-6.3c-1.8-1.8-3.9-2.3-6.3-2.4C23.7,5,16.3,5,13.8,5.1
			c-2.4,0.1-4.5,0.7-6.3,2.4c-1.8,1.8-2.3,3.9-2.4,6.3C5,16.3,5,23.7,5.1,26.2c0.1,2.4,0.7,4.5,2.4,6.3c1.8,1.8,3.9,2.3,6.3,2.4
			c2.5,0.1,9.9,0.1,12.4,0c2.4-0.1,4.5-0.7,6.3-2.4c1.8-1.8,2.3-3.9,2.4-6.3C35,23.7,35,16.3,34.9,13.8z M31.7,28.8
			c-0.5,1.3-1.5,2.3-2.9,2.9c-2,0.8-6.7,0.6-8.8,0.6c-2.2,0-6.9,0.2-8.8-0.6c-1.3-0.5-2.3-1.5-2.9-2.9c-0.8-2-0.6-6.7-0.6-8.8
			s-0.2-6.9,0.6-8.8c0.5-1.3,1.5-2.3,2.9-2.9c2-0.8,6.7-0.6,8.8-0.6c2.2,0,6.9-0.2,8.8,0.6c1.3,0.5,2.3,1.5,2.9,2.9
			c0.8,2,0.6,6.7,0.6,8.8S32.5,26.9,31.7,28.8z"
            />
          </g>
        </g>
        <g id="Layer_1_1_"></g>
      </svg>

      <style jsx>{`
        .st0 {
          fill-rule: evenodd;
          clip-rule: evenodd;
          fill: #b39c57;
        }
        .st1 {
          fill: #ffffff;
        }
      `}</style>
    </Fragment>
  );
};

export default InstagramIcon;
