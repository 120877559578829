import React from "react"
import PropTypes from "prop-types"
import FinkaLogoWhite from "components/svg/finka-logo-white"
import MailIcon from "components/svg/socials/mail-icon"
import TelegramIcon from "components/svg/socials/telegram-icon"
import LinkedinIcon from "components/svg/socials/linkedin-icon"
import TwitterIcon from "components/svg/socials/twitter-icon"
import FacebookIcon from "components/svg/socials/facebook-icon"
import InstagramIcon from "components/svg/socials/instagram-icon"
import FooterNav from "components/FooterNav"

const Footer = ({ locale }) => (
  <section className="charcoal-bg " id="footer">
    <FooterNav locale={locale} />
    <div className="row middle-sm">
      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <div className="box">
          <div className="logo">
            <FinkaLogoWhite />
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <div className="box">
          <p className="copyright normal-text stone">
            {" "}
            {locale == "de" ? "Urheberrecht" : "Copyright"} © Finka Gmbh
          </p>
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <div className="box end-md end-lg center-sm center-xs">
          <div className="socials">
            {" "}
            <Socials />{" "}
          </div>
        </div>
      </div>
    </div>
  </section>
)

const Socials = () => (
  <div id="socials">
    <div className="social-icon">
      <a href="mailto:info@finka.ch" target="_blank">
        <MailIcon />
      </a>
    </div>
    <div className="social-icon">
      <a href="https://t.me/finkatoken" target="_blank">
        <TelegramIcon />
      </a>
    </div>
    <div className="social-icon">
      <a href="https://www.linkedin.com/company/42466740/" target="_blank">
        <LinkedinIcon />
      </a>
    </div>
    <div className="social-icon">
      <a href="https://twitter.com/TokenFinka" target="_blank">
        <TwitterIcon />
      </a>
    </div>
    <div className="social-icon">
      <a
        href="https://web.facebook.com/Finka-Token-100435184965328/"
        target="_blank"
      >
        <FacebookIcon />
      </a>
    </div>
    <div className="social-icon">
      <a href="https://www.instagram.com/finka_token/" target="_blank">
        <InstagramIcon />
      </a>
    </div>
  </div>
)

Footer.propTypes = {
  locale: PropTypes.string.isRequired,
}

export default Footer
