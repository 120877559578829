import React, { Fragment } from "react";
const LinkedinIcon = () => {
  return (
    <Fragment>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 40 40"
        xml="preserve"
      >
        <g id="g5891">
          <path
            id="background"
            className="st0"
            d="M40,5c0-2.76-2.24-5-5-5H5C2.24,0,0,2.24,0,5v30c0,2.76,2.24,5,5,5h30c2.76,0,5-2.24,5-5V5z"
          />
          <g id="shapes">
            <rect
              id="rect11"
              x="4.77"
              y="13.96"
              className="st1"
              width="6.7"
              height="20.15"
            />
            <path
              id="path13-0"
              className="st2"
              d="M8.16,4.24c-2.29,0-3.79,1.51-3.79,3.48c0,1.93,1.45,3.48,3.7,3.48h0.04
			c2.34,0,3.79-1.55,3.79-3.48C11.87,5.75,10.46,4.24,8.16,4.24L8.16,4.24z"
            />
            <path
              id="path15"
              className="st2"
              d="M27.91,13.48c-3.55,0-5.15,1.95-6.04,3.33v-2.85h-6.7c0.09,1.89,0,20.15,0,20.15h6.7V22.86
			c0-0.6,0.04-1.2,0.22-1.64c0.48-1.2,1.59-2.45,3.44-2.45c2.42,0,3.39,1.85,3.39,4.56v10.78h6.7V22.55
			C35.62,16.36,32.32,13.48,27.91,13.48z"
            />
          </g>
        </g>
      </svg>
      <style jsx>{`
        .st0 {
          fill-rule: evenodd;
          clip-rule: evenodd;
          fill: #b39c57;
        }
        .st1 {
          fill-rule: evenodd;
          clip-rule: evenodd;
          fill: #ffffff;
        }
        .st2 {
          fill: #ffffff;
        }
      `}</style>
    </Fragment>
  );
};

export default LinkedinIcon;
