import React, { Fragment } from "react";
const TelegramIcon = () => {
  return (
    <Fragment>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 40 40"
        xml="preserve"
      >
        <g id="g3885" transform="translate(297.22034,0)">
          <path
            id="rect2987"
            className="st0"
            d="M-292.22,0h30c2.76,0,5,2.24,5,5v30c0,2.76-2.24,5-5,5h-30c-2.76,0-5-2.24-5-5V5
		C-297.22,2.24-294.98,0-292.22,0z"
          />
          <path
            id="path9"
            className="st1"
            d="M-264.01,7.36l-27.06,10.44c-1.85,0.74-1.84,1.77-0.34,2.23l6.75,2.11l2.58,7.92
		c0.31,0.87,0.16,1.21,1.07,1.21c0.7,0,1.01-0.32,1.4-0.7c0.25-0.24,1.73-1.68,3.37-3.28l7.02,5.19c1.29,0.71,2.22,0.34,2.55-1.2
		l4.61-21.71C-261.58,7.66-262.77,6.8-264.01,7.36z M-283.6,21.65l15.21-9.6c0.76-0.46,1.46-0.21,0.88,0.29l-13.02,11.75l-0.51,5.41
		L-283.6,21.65z"
          />
        </g>
      </svg>
      <style jsx>{`
        .st0 {
          fill: #b39c57;
        }
        .st1 {
          fill: #ffffff;
        }
      `}</style>
    </Fragment>
  );
};

export default TelegramIcon;
