import React, { Fragment } from "react"
import "../../styles/inline.css"

const FinkaLogoBlack = () => {
  return (
    <Fragment>
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 482.63 55"
        fill="#414042"
      >
        <title>FinkaLogoLongCharcoal</title>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Artwork">
            <path
              style={{
                fill: "#414042",
              }}
              d="M24.71,19.14H5.84V28.2H22.58v5.32H5.84V47H0V13.83H24.71Z"
            />
            <path
              style={{
                fill: "#414042",
              }}
              d="M49.15,13.83V47H43.31V13.83Z"
            />
            <path
              style={{
                fill: "#414042",
              }}
              d="M93.08,13.83h5.74V47H93.93L75.62,23.41V47H69.88V13.83h5.41l17.79,23Z"
            />
            <path
              style={{
                fill: "#414042",
              }}
              d="M144.27,47,130.61,30.2l-8.26,8.35V47h-2.47V13.83h2.47V35.65l21.16-21.82h3.37L132.36,28.49,147.49,47Z"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="180.66 13.59 178.28 13.59 162.82 47.04 165.38 47.04 169.6 37.74 169.63 37.74 173.17 29.98 173.17 29.98 179.42 16.3 184.6 27.69 189.18 37.74 189.19 37.74 193.42 47.04 196.12 47.04 180.66 13.59"
            />
            <rect
              style={{
                fill: "#414042",
              }}
              x="279.01"
              y="13.79"
              width="24.71"
              height="6.01"
            />
            <path
              style={{
                fill: "#414042",
              }}
              d="M294.28,13.77V47h-5.83V13.77Z"
            />
            <path
              style={{
                fill: "#414042",
              }}
              d="M476.89,13.77h5.74V47h-4.88L459.43,23.36V47H453.7V13.77h5.4l17.79,23Z"
            />
            <path
              style={{
                fill: "#414042",
                stroke: "#414042",
                strokeMiterlimit: "10",
                strokeWidth: "4px",
              }}
              d="M386.46,44.85,373.68,30.21,366,37.49v7.36h-2.31V15.9H366v19l19.79-19h3.15L375.32,28.68l14.16,16.17Z"
            />
            <path
              style={{
                fill: "#414042",
              }}
              d="M434.79,19.09H415.91v9.06h16.75v5.31H415.91V47h-5.83V13.77h24.71Z"
            />
            <rect
              style={{
                fill: "#414042",
              }}
              x="410.08"
              y="41.04"
              width="24.71"
              height="6.01"
            />
            <ellipse
              style={{
                fill: "none",
                stroke: "#414042",
                strokeMiterlimit: "10",
                strokeWidth: "6px",
              }}
              cx="331.99"
              cy="30.38"
              rx="13.58"
              ry="14.29"
            />
            <rect
              style={{
                fill: "#414042",
              }}
              x="222.56"
              y="8.59"
              width="26.43"
              height="3.22"
            />
            <rect
              style={{
                fill: "#414042",
              }}
              x="223.28"
              y="9.32"
              width="24.98"
              height="1.77"
            />
            <rect
              style={{
                fill: "#414042",
              }}
              x="246.83"
              y="6.31"
              width="8.05"
              height="3.22"
              transform="translate(59.96 170.86) rotate(-42.29)"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="251.72 6.39 251.74 1.26 254.95 0 254.94 6.4 251.72 6.39"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="239.33 53.32 244.59 46.49 246.91 48.76 242.1 55 239.33 53.32"
            />
            <rect
              style={{
                fill: "#414042",
              }}
              x="239.64"
              y="49.99"
              width="7.17"
              height="1.77"
              transform="translate(54.46 212.47) rotate(-52.38)"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="250.29 13.44 263.97 13.36 262.91 16.57 249.85 16.66 250.29 13.44"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="250.57 15.93 250.56 14.16 262.97 14.09 262.38 15.85 250.57 15.93"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="238.83 29.62 241.84 28.4 246.91 48.76 243.88 49.9 238.83 29.62"
            />
            <rect
              style={{
                fill: "#414042",
              }}
              x="241.97"
              y="29.19"
              width="1.77"
              height="19.91"
              transform="translate(-2.26 59.83) rotate(-13.98)"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="238.83 29.62 242.84 22.97 245.79 24.34 241.35 31.64 238.83 29.62"
            />
            <rect
              style={{
                fill: "#414042",
              }}
              x="238.62"
              y="26.25"
              width="7.1"
              height="1.77"
              transform="translate(93.14 219.94) rotate(-58.69)"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="242.84 22.97 249.59 22 250.05 25.19 242.94 26.22 242.84 22.97"
            />
            <rect
              style={{
                fill: "#414042",
              }}
              x="243.39"
              y="23.23"
              width="5.74"
              height="1.77"
              transform="translate(-0.91 35.71) rotate(-8.28)"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="245.81 18.14 248.93 17.27 251.18 32.67 248.26 33.16 245.81 18.14"
            />
            <rect
              style={{
                fill: "#414042",
              }}
              x="247.71"
              y="18.14"
              width="1.77"
              height="14.12"
              transform="translate(-0.81 40.46) rotate(-9.29)"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="245.81 18.14 250.29 13.44 252.88 15.35 247.9 20.63 245.81 18.14"
            />
            <rect
              style={{
                fill: "#414042",
              }}
              x="246.3"
              y="15.99"
              width="5.8"
              height="1.77"
              transform="translate(65.89 186.54) rotate(-46.66)"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="250.49 21.01 257.69 21 257.21 24.22 250.94 24.23 250.49 21.01"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="258.02 21.15 260.57 13.38 263.97 13.36 261.23 21.71 258.02 21.15"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="260.62 21.24 258.93 20.69 261.1 14.1 262.97 14.09 260.62 21.24"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="255.04 21.75 259.92 18.73 261.23 21.71 257.15 24.22 255.04 21.75"
            />
            <rect
              style={{
                fill: "#414042",
              }}
              x="256.18"
              y="20.72"
              width="4.29"
              height="1.77"
              transform="matrix(0.85, -0.53, 0.53, 0.85, 27.18, 138.97)"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="246.75 36.65 248.4 30.66 251.18 32.67 250 36.94 246.75 36.65"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="237.13 47.33 243.05 40.19 245.18 42.43 239.68 49.13 237.13 47.33"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="245.41 37.75 247.84 34.49 250 36.94 246.4 41.48 245.41 37.75"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="237.88 49.4 237.13 47.33 238.55 45.64 240.72 46.03 241.47 48.1 240.05 49.79 237.88 49.4"
            />
            <rect
              style={{
                fill: "#414042",
              }}
              x="219.06"
              y="3.9"
              width="3.22"
              height="8.05"
              transform="translate(66.32 165.83) rotate(-47.71)"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="219.81 6.4 219.79 1.27 216.58 0.01 216.59 6.41 219.81 6.4"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="232.61 53.32 227.35 46.49 225.03 48.76 229.84 55 232.61 53.32"
            />
            <rect
              style={{
                fill: "#414042",
              }}
              x="227.81"
              y="47.31"
              width="1.77"
              height="7.17"
              transform="translate(16.48 150.18) rotate(-37.62)"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="221.66 13.44 207.97 13.36 209.03 16.57 222.09 16.66 221.66 13.44"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="221.37 15.93 221.38 14.16 208.98 14.09 209.56 15.85 221.37 15.93"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="233.12 29.61 230.1 28.39 225.03 48.76 228.06 49.9 233.12 29.61"
            />
            <rect
              style={{
                fill: "#414042",
              }}
              x="219.12"
              y="38.26"
              width="19.91"
              height="1.77"
              transform="translate(135.75 251.98) rotate(-76.02)"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="233.12 29.61 229.1 22.97 226.16 24.33 230.59 31.63 233.12 29.61"
            />
            <rect
              style={{
                fill: "#414042",
              }}
              x="228.85"
              y="23.6"
              width="1.77"
              height="7.1"
              transform="matrix(0.85, -0.52, 0.52, 0.85, 19.35, 123.34)"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="229.1 22.97 222.36 22 221.89 25.18 229.01 26.22 229.1 22.97"
            />
            <rect
              style={{
                fill: "#414042",
              }}
              x="224.79"
              y="21.23"
              width="1.77"
              height="5.74"
              transform="translate(169.32 243.96) rotate(-81.72)"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="226.13 18.14 223.01 17.27 220.76 32.66 223.68 33.16 226.13 18.14"
            />
            <rect
              style={{
                fill: "#414042",
              }}
              x="216.29"
              y="24.33"
              width="14.12"
              height="1.77"
              transform="translate(162.41 241.56) rotate(-80.71)"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="226.13 18.14 221.66 13.44 219.07 15.35 224.04 20.62 226.13 18.14"
            />
            <rect
              style={{
                fill: "#414042",
              }}
              x="221.83"
              y="13.97"
              width="1.77"
              height="5.8"
              transform="translate(49.15 157.46) rotate(-43.34)"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="221.46 21 214.26 21 214.74 24.22 221 24.23 221.46 21"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="213.93 21.15 211.37 13.37 207.97 13.36 210.72 21.71 213.93 21.15"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="211.33 21.24 213.01 20.69 210.84 14.1 208.97 14.09 211.33 21.24"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="216.91 21.75 212.02 18.73 210.72 21.71 214.79 24.22 216.91 21.75"
            />
            <rect
              style={{
                fill: "#414042",
              }}
              x="212.73"
              y="19.47"
              width="1.77"
              height="4.29"
              transform="translate(82.98 192) rotate(-58.3)"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              style={{
                fill: "#414042",
              }}
              points="225.2 36.64 223.54 30.66 220.76 32.66 221.94 36.94 225.2 36.64"
            />
            <rect
              style={{
                fill: "#414042",
              }}
              x="221.92"
              y="31.7"
              width="1.77"
              height="4.76"
              transform="translate(-1.04 60.51) rotate(-15.43)"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="234.81 47.32 228.9 40.19 226.76 42.43 232.26 49.13 234.81 47.32"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="226.53 37.74 224.1 34.49 221.94 36.94 225.54 41.48 226.53 37.74"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="234.06 49.4 234.81 47.32 233.39 45.63 231.22 46.02 230.47 48.1 231.89 49.79 234.06 49.4"
            />
            <polygon
              style={{
                fill: "#414042",
              }}
              points="242.1 55 242.5 51.77 229.47 51.77 229.84 55 242.1 55"
            />
          </g>
        </g>
      </svg>
      {/* <style jsx>{`

        .cls-2 {
          fill: '#414042',
          strokeMiterlimit: '10',
          strokeWidth: '4px'
        }

        .cls-3 {
          stroke: #414042;
          stroke-miterlimit: 10;
           fill: none;
          stroke-width: 6px;
        }

      `}</style> */}
    </Fragment>
  )
}

export default FinkaLogoBlack
