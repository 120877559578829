import React, { Fragment } from "react";
const FacebookIcon = () => {
  return (
    <Fragment>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 40 40"
        style={{ enableBackground: "new 0 0 40 40" }}
        xmlSpace="preserve"
      >
        <path
          id="background"
          className="st0"
          d="M40,5c0-2.8-2.2-5-5-5H5C2.2,0,0,2.2,0,5v30c0,2.8,2.2,5,5,5h30c2.8,0,5-2.2,5-5V5z"
        />
        <g id="Padding__x26__Artboard"></g>
        <g id="Icons">
          <g>
            <path
              className="st1"
              d="M25,11.1c2.3,0,3,0,3,0c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3l0.1-5.3c0-0.4,0-0.4-1.4-0.4L27,5
			c-0.3,0-0.7,0-1,0c-1.5,0-3.1-0.1-4.6,0.4c-1.6,0.5-2.8,1.3-3.6,2.5c-0.9,1.3-1.4,3.1-1.4,4.9v3.8H12c-0.2,0-0.4,0.2-0.4,0.4
			l-0.1,5.7c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1l4.5,0l0.1,11.5c0,0.2,0.2,0.4,0.4,0.4h5.6c0.2,0,0.4-0.2,0.4-0.4L23,23.1
			h4.2c0.2,0,0.4-0.1,0.4-0.3l0.8-5.6c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1L23,16.6l0-4C23,12.5,23.1,11.2,25,11.1z"
            />
          </g>
        </g>
      </svg>

      <style jsx>{`
        .st0 {
          fill-rule: evenodd;
          clip-rule: evenodd;
          fill: #b39c57;
        }
        .st1 {
          fill: #ffffff;
        }
      `}</style>
    </Fragment>
  );
};

export default FacebookIcon;
